import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import HasRolePermission from "components/shared/has-role-permission";
import { formatDate } from "utils/dates";
import { Link } from "react-router-dom";
import { InlineWaiting, Checkbox, IconButton } from "components/ui";
import { Formik, Form } from "formik";
import {
  MdEdit,
  MdSave,
  MdCancel,
  MdWarning,
  MdFindReplace,
  MdDelete
} from "react-icons/lib/md";
import FormikField from "components/forms/formik-field";
import { PatientProfileContext } from "components/profile/context";
import { useFetch } from "hooks";
import * as msg from "actions/message";
import { put } from "utils/api";
import { getApiErrorMessage } from "utils/misc";

const HcpcRow = ({
  eligiblity,
  expanded,
  toggleExpanded,
  editRow,
  setEditRow,
  patientId
}) => {
  const dispatch = useDispatch();
  const { fetchPatientHCPCHistoryAPI } = React.useContext(
    PatientProfileContext
  );
  const updatePatientHCPCHistoryAPI = useFetch({
    apiFn: values => put(`patients/${patientId}/edit-hcpcs-ordered`, values),
    onSuccess: () => {
      fetchPatientHCPCHistoryAPI.fetchData(patientId);
    },
    onError: err => {
      dispatch(
        msg.errorMessage(
          getApiErrorMessage(err, "Error updating patient HCPC History record")
        )
      );
    }
  });
  return (
    <>
      <div className="eligibility-table-row" onClick={toggleExpanded}>
        <div style={{ color: eligiblity.isEligible ? "green" : "default" }}>
          {eligiblity.productType}
        </div>
        <div>{formatDate(eligiblity.eligibilityDate)}</div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <span>{formatDate(eligiblity.lastOrderDate)}</span>
          {eligiblity.history.some(x => x.replacementItem) && (
            <span
              style={{
                paddingLeft: 5,
                dispaly: "flex",
                alignItems: "baseline"
              }}
            >
              <span title="Replacment Item on file">
                <MdFindReplace size={20} color="#ff7800" />
              </span>
            </span>
          )}
          {eligiblity.lastOrderedItemZeroBilled && (
            <span
              style={{
                paddingLeft: 5,
                dispaly: "flex",
                alignItems: "baseline"
              }}
            >
              <span title="Last Item Ordered was Zero Billed">
                <MdWarning size={18} color="#ff7800" />
              </span>
            </span>
          )}
          {eligiblity.adjustedItemQty > 0 && (
            <span
              style={{
                paddingLeft: 5,
                dispaly: "flex",
                alignItems: "baseline"
              }}
            >
              <span
                title={`Adjusted eligible quantity of last item to ${eligiblity.adjustedItemQty}`}
              >
                <MdWarning size={18} color="#dd9830" />
              </span>
            </span>
          )}
        </div>
        <div style={{ marginRight: 15, fontSize: "0.9rem" }}>
          {eligiblity.lastOrderedItemName}
        </div>
        <div style={{ marginRight: 15, fontSize: "0.9rem" }}>
          {eligiblity.lastOrderedItemId}
        </div>
        {eligiblity.insRuleDaysToPush == null ? (
          <div />
        ) : (
          <div>
            {eligiblity.insRuleDaysToPush}|{eligiblity.insRuleQtyAllowed}|
            {eligiblity.companyOffsetDays}
          </div>
        )}
      </div>
      {expanded && (
        <div className="hcpc-history-table">
          {!eligiblity.history || eligiblity.history?.length == 0 ? (
            <div style={{ paddingLeft: 60 }}>No Order History</div>
          ) : (
            <div className="hcpc-history-table-header">
              <div>HCPC</div>
              <div>Order Date</div>
              <div>QTY</div>
              <div>Zero Billed</div>
              <div>Replacement</div>
              {/* <div>Offered</div> */}
              {eligiblity.productType == "Machine" && (<div>Serial Number</div>)}
              <div>Origin</div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start"
                }}
              >
                Item Name
              </div>
              <div>Item ID</div>
            </div>
          )}
          {eligiblity.history?.map((x, i) => (
            <div className="eligibility-history-row-container" key={i}>
              {editRow === x.hcpcOrderedID ? (
                <EditHcpcHistoryRow
                  history={x}
                  eligiblity={eligiblity}
                  setEditRow={setEditRow}
                  patientId={patientId}
                  updatePatientHCPCHistoryAPI={updatePatientHCPCHistoryAPI}
                />
              ) : (
                <div className="hcpc-history-table-row">
                  <div>{x.hcpc}</div>
                  <div>{formatDate(x.service_date)}</div>
                  <div>{x.qty}</div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Checkbox
                      value={x.zeroBilled}
                      color={x.zeroBilled ? "#ff7800" : "default"}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Checkbox
                      value={x.replacementItem}
                      color={x.replacementItem ? "#ff7800" : "default"}
                    />
                  </div>
                  {/* <div */}
                  {/*   style={{ */}
                  {/*     display: "flex", */}
                  {/*     flex: 1, */}
                  {/*     width: "100%", */}
                  {/*     alignContent: "center", */}
                  {/*     alignItems: "center", */}
                  {/*     justifyContent: "center" */}
                  {/*   }} */}
                  {/* > */}
                  {/*   <Checkbox */}
                  {/*     value={x.offered} */}
                  {/*     color={x.offered ? "#ff7800" : "default"} */}
                  {/*   /> */}
                  {/* </div> */}
                  {eligiblity.productType == "Machine" && (<div>{x.serialNumber}</div>)}
                  <div>
                    {x.creationMethod == "S3" && x.orderId ? (
                      <Link to={`/orders/${x.orderId}`}>
                        {x.creationMethod}
                      </Link>
                    ) : (
                      x.creationMethod
                    )}{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-start"
                    }}
                  >
                    {x.productName}
                  </div>
                  <div>{x.productID}</div>
                  <div>
                    {/* Only allow editing if the row was not generated from S3 Order */}
                    {!editRow && !x.replacementItem && (
                      <HasRolePermission allowedRoles={["Administrator"]}>
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() => {
                            setEditRow(x.hcpcOrderedID);
                          }}
                        />
                      </HasRolePermission>
                    )}
                    {x.creationMethod != "S3" && <HasRolePermission allowedRoles={["Administrator"]}>
                      <IconButton
                        icon={<MdDelete color="red" />}
                        disabled={updatePatientHCPCHistoryAPI.isFetching}
                        onClick={async () => {
                          if (
                            confirm("Do you really want to remove this record?")
                          ) {
                            await updatePatientHCPCHistoryAPI.fetchData({
                              hcpcOrderedID: x.hcpcOrderedID,
                              active: false
                            });
                          }
                        }}
                      />
                    </HasRolePermission>}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

HcpcRow.propTypes = {
  eligiblity: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  toggleExpanded: PropTypes.func.isRequired,
  editRow: PropTypes.number,
  setEditRow: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired
};

export default HcpcRow;

const EditHcpcHistoryRow = ({
  history,
  eligiblity,
  setEditRow,
  updatePatientHCPCHistoryAPI
}) => {
  return (
    <Formik
      initialValues={{
        hcpcOrderedID: history.hcpcOrderedID,
        quantity: history.qty,
        serialNumber: history.serialNumber,
        zeroBilled: history.zeroBilled,
        service_date: formatDate(history.service_date, "yyyy-MM-DD"),
        replacementItem: history.replacementItem
      }}
      onSubmit={async values => {
        await updatePatientHCPCHistoryAPI.fetchData(values);
        setEditRow(null);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <div className="hcpc-history-table-row">
            <div>{history.hcpc}</div>
            {/* <div>{formatDate(history.service_date)}</div> */}
            <div>
              <FormikField name="service_date" type="date" />
            </div>
            <div>
              <FormikField name="quantity" type="number" />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FormikField label="" name="zeroBilled" type="checkbox" />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FormikField
                label=""
                name="replacementItem"
                type="checkbox"
                disabled
              />
            </div>
            {/* <div */}
            {/*   style={{ */}
            {/*     display: "flex", */}
            {/*     flex: 1, */}
            {/*     width: "100%", */}
            {/*     alignContent: "center", */}
            {/*     alignItems: "center", */}
            {/*     justifyContent: "center" */}
            {/*   }} */}
            {/* > */}
            {/*   <FormikField label="" name="offered" type="checkbox" /> */}
            {/* </div> */}
            {eligiblity.productType == "Machine" && (
              <div>
                <FormikField name="serialNumber" type="text" />
              </div>
            )}
            <div>
              {history.creationMethod == "S3" && history.orderId ? (
                <Link to={`/orders/${history.orderId}`}>
                  {history.creationMethod}
                </Link>
              ) : (
                history.creationMethod
              )}{" "}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-start"
              }}
            >
              {history.productName}
            </div>
            <div>{history.productID}</div>
            {/* Only allow editing if the row was not generated from S3 Order */}
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "space-between"
                }}
              >
                <IconButton
                  type="button"
                  icon={<MdCancel color="orange" size={18} />}
                  onClick={() => {
                    setEditRow(null);
                  }}
                />
                <IconButton
                  type="submit"
                  icon={<MdSave color="green" size={18} />}
                  onClick={submitForm}
                />
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditHcpcHistoryRow.propTypes = {
  history: PropTypes.object.isRequired,
  eligiblity: PropTypes.object.isRequired,
  editRow: PropTypes.number,
  setEditRow: PropTypes.func.isRequired,
  updatePatientHCPCHistoryAPI: PropTypes.object.isRequired
};
